// src/App.tsx
import React, { useEffect, useState } from 'react';
import './App.css';

const App: React.FC = () => {
  const [orientation, setOrientation] = useState<number | null>(null);

  useEffect(() => {
    const handleOrientation = (event: DeviceOrientationEvent) => {
      if (event.absolute && typeof event.alpha === 'number') {
        const currentOrientation = event.alpha;
        setOrientation(currentOrientation);

        if (currentOrientation >= 355 || currentOrientation <= 5) {
          navigator.vibrate(1000); // Vibrate for 1 second when pointing north.
        }
      }
    };

    window.addEventListener('deviceorientation', handleOrientation);

    return () => {
      window.removeEventListener('deviceorientation', handleOrientation);
    };
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <p>Current Orientation: {orientation !== null ? orientation.toFixed(2) : 'N/A'} degrees</p>
      </header>
    </div>
  );
};

export default App;
